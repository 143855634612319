import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/wdrozenie-b2b-kroki.jpg";
import Lightbox from "../components/lightBox";
import ResponsiveEmbed from "react-responsive-embed";
import { Link } from "gatsby";
import * as styles from "./index.module.css";


export const query = graphql`
  {
    zdjecie1: file(relativePath: { eq: "pulpit-klienta-b2b.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie2: file(relativePath: { eq: "obsluga-zapytan-ofertowych.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    lista: file(relativePath: { eq: "wdrozenie-b2b-lista.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const Wdrozenieb2b = ({ data }) => {
  return (
    <Artykul
      title="5 etapów wdrożenia systemu B2B w organizacji"
      keywords={["wdrożenie systemu b2b"]}
      articleImage={obrazekArtykulu}
      articleImageAlt="5 etapów wdrożenia systemu B2B w organizacji"
      metaTitle="5 etapów wdrożenia systemu B2B w organizacji"
      metaDescription="System B2B - 5 kroków skutecznego wdrożenia ✅ Kroki sukcesu • Vlog 2020 "
    >
      <p>
        Wdrożenie systemu B2B można podzielić na kilka etapów. Marta Kaźmierska,
        BDM Questy, omówi pięć kluczowych kroków skutecznego wdrożenia platformy
        sprzedażowej.
      </p>
      <p>
        <ul>
          <li>Wizja biznesowa</li>
          <li>Przygotowanie platformy</li>
          <li>Szkolenie pracowników</li>
          <li>Wdrożenie klienta</li>
          <li>Analiza, testowanie i doskonalenie</li>
        </ul>
      </p>
      <ResponsiveEmbed
        src="https://www.youtube.com/embed/HnRLj0RH4DI"
        allowFullScreen
        ratio="16:9"
      />
      <h2
        style={{
          marginTop: 30,
          marginBottom: 30,
        }}
      >
        Wizja biznesowa
      </h2>
      <p>
        Wizja biznesowa to podstawa od której należy rozpocząć temat wdrożenia
        systemu B2B. Jeżeli ten etap jest dopiero przed Tobą, w jej określeniu
        może pomóc:
      </p>
      <p>
        <ul>
          <li>zdefiniowanie problemów z jakimi zmaga się Twoja sprzedaż,</li>
          <li>określenie celów jakie chcecie osiągnąć poprzez wdrożenie, </li>
          <li>wypisanie procesów, które ma obsłużyć platforma B2B.</li>
        </ul>
      </p>
      <p>
        Określenie wizji jest indywidualne dla każdej firmy. Wypisaliśmy jednak
        po kilka przykładów odpowiedzi na powyższe pytania, które warto sobie
        zadać.
      </p>
      <p>
        Przykłady wyzwań z jakimi spotykają się producenci, dystrybutorzy lub
        hurtownicy, sprzedający produkty w segmencie B2B:
      </p>
      <p>
        <ul>
          <li>
            brak możliwości szybkiego przekazania dostępu do indywidualnej
            oferty produktowej,
          </li>
          <li>
            konieczność poświęcania czasu na: przepisywanie zamówień do programu
            handlowo-magazynowego, przekazywanie klientom informacji o
            dostępności produktów czy rozliczeniach,
          </li>
          <li>problematyczne informowanie o nowościach lub wyprzedażach,</li>
          <li>brak wspólnej bazy wiedzy o kliencie i jego zamówieniach.</li>
        </ul>
      </p>
      <p>
        Zastanów się jaki jest cel wdrożenia systemu B2B w Twojej firmie oraz
        cele pośrednie. Najczęściej spotykamy się takimi celami jak:
      </p>
      <p>
        <ul>
          <li>osiągnięcie wzrostu sprzedaży o X%,</li>
          <li>
            chęć sprzedaży produktów online 24 godziny przez 7 dni w tygodniu,
            czyli bez ograniczeń,
          </li>
          <li>
            automatyzacja powtarzalnych czynności w obszarze sprzedaży i obsługi
            klienta,
          </li>
          <li>zmniejszenie nadwyżki magazynowej,</li>
          <li>przyspieszenie obsługi zamówień i zapytań ofertowych,</li>
          <li>usprawnienie komunikacji z kontrahentami.</li>
        </ul>
      </p>
      <p>
        Zdefiniowanie kluczowych procesów również pomoże Ci w odpowiednim
        wyborze systemu B2B i przygotowaniu do sprzedaży produktów online.
        System B2B może wesprzeć Twój dział handlowy w prezentacji oferty,
        obsłudze zamówień, obsłudze zapytań ofertowych i negocjacji cen.
      </p>
      <p>
        Wyznacz osobę odpowiedzialną za projekt (pracownika), która będzie miała
        czas i motywację do zapewnienia sukcesu Twojej firmie.
      </p>
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz korzystać z dopasowanej platformy B2B?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Skontaktuj się z nami już teraz i uzyskaj dostęp do darmowej 30-dniowej wersji próbnej platformy B2B

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i poznać możliwości platformy B2B
            </Link>
          </div>
          </div>
    </section>
      <h2
        style={{
          marginTop: 30,
          marginBottom: 30,
        }}
      >
        Dopasowanie platformy B2B
      </h2>
      <p>
        Zanim przekażesz dostęp do platformy swoim kontrahentom zadbaj o
        czytelność informacji, które się w niej znajdują. Zwróć uwagę na to czy:
      </p>
      <p>
        <ul>
          <li>zdjęcia produktów są wyraźne i atrakcyjne, </li>
          <li>opisy produktów są zrozumiałe,</li>
          <li>do produktów są dodane odpowiednie znaczniki (np. promocja),</li>
          <li>uzupełnione są informacje o produktach powiązanych,</li>
          <li>
            klient ma dostęp do wszystkich niezbędnych informacji np. rozliczeń.
          </li>
        </ul>
      </p>
      <p>
        Jeżeli chcesz wyświetlić inne informacje danej grupie kontrahentów
        stwórz nowy profil i nadaj mu uprawnienia.{" "}
      </p>
      <h2
        style={{
          marginTop: 30,
          marginBottom: 30,
        }}
      >
        Szkolenie pracowników
      </h2>
      <p>
        Sam zakup platformy B2B nie wystarczy, aby osiągnąć sukces. Kluczową
        rolę odgrywają pracownicy, którzy będą na co dzień pracować z systemem
        B2B.
      </p>
      <p>
        Dobrą praktyką jest zaangażowanie pracowników już na pierwszych etapach
        wyboru platformy (np. w celu ustalenia wyzwań z jakimi się spotykają na
        co dzień i powiązanych z nimi potrzeb).{" "}
      </p>
      <p>
        Na etapie przekazania systemu pracownikom niezbędne jest przekazanie
        wizji i szkolenie. Upewnij się, że Twoi handlowcy wiedzą:
      </p>
      <p>
        <ul>
          <li>jak zalogować się do panelu,</li>
          <li>w jaki sposób zweryfikować zamówienia,</li>
          <li>
            czy wiedzą jak wygląda panel klienta, jak się do niego zalogować
            oraz jak zachęcić klientów do korzystania z platformy.
          </li>
        </ul>
      </p>
      <h2
        style={{
          marginTop: 30,
          marginBottom: 30,
        }}
      >
        Przekazanie platformy klientom
      </h2>
      <p>
        Zachęć klientów do składania zamówień przez system B2B, wskazując
        korzyści np.:
      </p>
      <p>
        <ul>
          <li>nieograniczony dostęp do oferty produktowej,</li>
          <li>
            swoboda w składaniu zamówień (z dowolnego miejsca o dowolnym
            czasie),
          </li>
          <li>szybkie informacje o dostępności produktów,</li>
          <li>możliwość tworzenia ofert dla klienta końcowego. </li>
        </ul>
      </p>
      <p>
        Skorzystaj ze specjalnej promocji, która zachęci klientów do składania
        zamówień online.
      </p>
      <h2
        style={{
          marginTop: 30,
          marginBottom: 30,
        }}
      >
        Analiza, testowanie i doskonalenie
      </h2>
      <p>
        Monitoruj aktywność Twoich klientów w platformie. Sprawdź, kto logował
        się do systemu B2B, a kto nie. Sprawdzaj wartości koszyków swoich
        klientów.{" "}
      </p>
      <p>
        Jeżeli widzisz, że klient loguje się, ale nie składa zamówień lub nie
        loguje się wcale skontaktuj się z nim i ustal przyczynę. Testuj różne
        rozwiązania, dodawaj nowe ogłoszenia, promuj produkty, dopasuj produkty
        powiązane. Obserwuj jak klienci reagują na zmiany i stale doskonal swoją
        ofertę.{" "}
      </p>
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz korzystać z dopasowanej platformy B2B?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Skontaktuj się z nami już teraz i uzyskaj dostęp do darmowej 30-dniowej wersji próbnej platformy B2B

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i poznać możliwości platformy B2B
            </Link>
          </div>
          </div>
    </section>

      <br />
      <br />
      <p>Warto przeczytać:</p>
      <p>
        <ul>
          <li>
            <Link to="/system-b2b-funkcje/">
              Najważniejsze funkcje systemu B2B
            </Link>
          </li>
          <li>
            <Link to="/czym-jest-platforma-b2b/">
              Co to jest platforma B2B?
            </Link>
          </li>
        </ul>
      </p>
    </Artykul>
  );
};

export default Wdrozenieb2b;
